<template>
  <!--发布需求-->
  <component :is="activeComponent" :value="value"></component>
</template>

<script>
import demand from "./sub/demand.vue";
import recruitment from "./sub/recruitment.vue";
import { get } from "@/api/demand";
export default {
  props:['id'],
  data() {
    return {
      activeComponent: "demand",
      value:{},
    };
  },
  components: {
    demand,
    recruitment,
  },
  async mounted() {
    let d = await get(this.id)
    this.activeComponent = d.type.toLocaleLowerCase()
    this.value = d
  }
};
</script>